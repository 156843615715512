html, body {
  min-width: 480px;
  height: 100%;
  min-height: 700px;
  overflow: hidden;
}

body {
  text-transform: lowercase;
  background: #333;
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: monospace;
  display: flex;
}

button {
  color: #0288d1;
  text-transform: lowercase;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  border-radius: .25rem;
  min-width: 6.25rem;
  margin: 1rem 0;
  padding: .5rem 0;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.25;
  display: block;
}

#tetris {
  background: #555;
  flex-flow: row;
  display: flex;
  position: relative;
}

#scene {
  background: #2f2f2f;
  height: 100%;
}

#gameOver {
  color: #ffe100;
  text-align: center;
  z-index: 10;
  background: #000000bf;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#gameOver button {
  margin: 1rem auto;
}

#gameOver h1 {
  margin-top: 50%;
}

#gameOver h3 {
  color: #0288d1;
}

#side {
  text-align: center;
  background-color: #37474f;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#info {
  width: 100%;
}

#preview {
  background: #37474f;
  width: 80px;
  height: 80px;
  margin: 10px auto;
}

#levelInfo {
  color: #fff;
  margin: .5rem 0;
  font-size: 1.25rem;
}

#level {
  color: #0288d1;
  font-size: 2rem;
  display: block;
}

#scoreInfo {
  color: #fff;
  margin: 0;
  font-size: 1.25rem;
}

#score {
  color: #0288d1;
  display: block;
}

#rewardInfo {
  color: #ff0;
  font-weight: bold;
}

.invisible {
  visibility: hidden;
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

#pause, .is--running #start {
  display: none;
}

.is--running #pause {
  display: block;
}

footer {
  color: #fff;
  flex: 0 0 100%;
  margin-top: 1rem;
}

social-links {
  display: block;
}
/*# sourceMappingURL=index.e8bb9212.css.map */
