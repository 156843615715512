html,
body {
	height: 100%;
	min-width: 480px;
	min-height: 700px;
	overflow: hidden;
}

body {
	font-family: monospace;
	text-transform: lowercase;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	align-content: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	background: #333;
}

button {
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.25;
	color: #0288d1;
	text-transform: lowercase;
	min-width: 6.25rem;
	display: block;
	padding: 0.5rem 0;
	border: 1px solid;
	margin: 1rem 0;
	background-color: #fff;
	border-radius: 0.25rem;
	box-shadow: none;
	appearance: none;
	cursor: pointer;
}

#tetris {
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	background: #555;
}

#scene {
	height: 100%;
	background: #2f2f2f;
}

#gameOver {
	color: #ffe100;
	text-align: center;
	display: none;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.75);
	z-index: 10;
}

#gameOver button {
	margin: 1rem auto;
}

#gameOver h1 {
	margin-top: 50%;
}

#gameOver h3 {
	color: #0288d1;
}

#side {
	text-align: center;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: space-between;
	background-color: #37474f;
}

#info {
	width: 100%;
}

#preview {
	width: 80px;
	height: 80px;
	background: #37474f;
	margin: 10px auto;
}

#levelInfo {
	color: #fff;
	font-size: 1.25rem;
	margin: 0.5rem 0;
}

#level {
	color: #0288d1;
	font-size: 2rem;
	display: block;
}

#scoreInfo {
	color: #fff;
	font-size: 1.25rem;
	margin: 0;
}

#score {
	color: #0288d1;
	display: block;
}

#rewardInfo {
	color: yellow;
	font-weight: bold;
}

.invisible {
	visibility: hidden;
}

.animated {
	animation-duration: 1.5s;
	animation-fill-mode: both;
}

@-webkit-keyframes fadeOutUp {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes fadeOutUp {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

.fadeOutUp {
	animation-name: fadeOutUp;
}

#pause,
.is--running #start {
	display: none;
}

.is--running #pause {
	display: block;
}

footer {
	color: #fff;
	flex: 0 0 100%;
	margin-top: 1rem;
}

social-links {
	display: block;
}
